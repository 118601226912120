import styles from './spinner.module.css'

interface SpinnerProps {
  isVisible?: boolean
}

const Spinner = ({ isVisible }: SpinnerProps) => (
  <div style={isVisible ? {} : { display: 'none' }} className={styles.spinnerContainer}>
    <div className={styles.spinner}></div>
  </div>
)

export default Spinner
