import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'modern-normalize/modern-normalize.css'
import 'regenerator-runtime'
import './index.css'
import { lazy, Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import Spinner from 'lib/components/common/spinner'
import 'react-toastify/dist/ReactToastify.css'

const rootElement = document.getElementById('root')
// eslint-disable-next-line react-refresh/only-export-components
const App = lazy(() => import('./app'))

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <BrowserRouter>
      <Suspense fallback={<Spinner isVisible />}>
        <App />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="dark"
          pauseOnFocusLoss
          draggable
        />
      </Suspense>
    </BrowserRouter>
  )
} else {
  console.error('Failed to find the root element')
}
